<template>
  <div class="main-wrapper">
    <AppHeader />
    <main class="main" :class="{ fullWidth, paddingNone }">
      <slot />
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader";
import AppFooter from "@/components/layouts/AppFooter";
export default {
  name: "DefaultLayout",
  components: { AppFooter, AppHeader },
  props: {
    fullWidth: {
      type: Boolean,
    },
    paddingNone: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  background: #fff 0 0 no-repeat padding-box;
  padding: 100px 180px 60px;
  flex: 1;
  @media screen and (max-width: 1440px) {
    padding: 60px 110px;
  }
  @media screen and (max-width: 1160px) {
    padding: 60px 80px;
  }
  @media screen and (max-width: 1024px) {
    padding: 60px;
  }
  @media screen and (max-width: 550px) {
    padding: 24px 40px;
  }
  @media screen and (max-width: 375px) {
    padding: 24px 20px;
  }
  &.fullWidth {
    padding: 100px 140px;
    @media screen and (max-width: 1440px) {
      padding: 60px 110px;
    }
    @media screen and (max-width: 1160px) {
      padding: 60px 80px;
    }
    @media screen and (max-width: 1024px) {
      padding: 60px;
    }
    @media screen and (max-width: 550px) {
      padding: 24px 30px;
    }
    @media screen and (max-width: 375px) {
      padding: 24px 20px;
    }
  }
  &.paddingNone {
    padding: 0;
  }
}
</style>
