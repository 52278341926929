import { defineStore } from "pinia";
import apiAxios from "@/api";
import router from "../router";
import { useToast } from "vue-toastification";
const toast = useToast();

export const useScheduleStore = defineStore("schedule", {
  state: () => {
    return {
      scheduleData: {},
      scheduleStep: 1,
      scheduleDate: "",
    };
  },
  getters: () => {},
  actions: {
    setScheduleStepData(payload) {
      this.scheduleData = Object.assign(this.scheduleData, payload);
    },
    nextStep() {
      if (
        JSON.parse(localStorage.getItem("authenticated")) &&
        this.scheduleStep === 1
      ) {
        this.scheduleStep = 3;
      } else {
        this.scheduleStep += 1;
      }
      let domRect = document
        .querySelector(".schedule-header")
        ?.getBoundingClientRect();
      if (domRect?.left && domRect?.top) {
        window.scrollTo(
          domRect.left + document.documentElement.scrollLeft,
          domRect.top + document.documentElement.scrollTop
        );
      }
    },
    prevStep() {
      if (
        JSON.parse(localStorage.getItem("authenticated")) &&
        this.scheduleStep === 3
      ) {
        this.scheduleStep = 1;
      }
      if (this.scheduleStep > 1) this.scheduleStep -= 1;
    },
    resetSteps() {
      this.scheduleStep = 1;
    },
    setStep(val) {
      this.scheduleStep = val;
    },
    clearInfo() {
      this.scheduleData = {};
    },
    removeProperty(val) {
      delete this.scheduleData[val];
    },
    async makeOrderRequest(payload) {
      try {
        await apiAxios.Order.createOrder(payload).then((response) => {
          // console.log("response store: ", response);
          this.scheduleDate = this.scheduleData.first_date_choice;
          // console.log("payload: ", payload);
          // debugger;
          this.setScheduleStepData(response);
          this.nextStep();
          this.clearInfo();
        });
      } catch (e) {
        // console.log("catch: ", e);
        if (e.result === "tokens refreshed successfully") {
          // console.log("token refreshed");
          this.setScheduleStepData(e);
          this.nextStep();
          this.clearInfo();
        }
        // debugger;
        if (e.response.status === 401) {
          toast.error("Autorization error");
          localStorage.clear();
          router.push({
            path: `/`,
          });
          return;
        }
        if (e?.response?.data?.message) {
          toast.error(e.response.data.message);
          return Promise.reject(e);
        }
      }
    },
  },
  persist: true,
});
