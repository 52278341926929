<template>
  <nav>
    <ul class="menu">
      <li
        v-if="isLoggedIn"
        class="menu__item menu__item--dropdown"
        @click="toggle"
        :class="{ open: accountOpen }"
      >
        <a class="menu__link menu__link--toggle" href="#">
          <span>Account</span>
          <img src="/images/Account.svg" alt="" />
        </a>
        <ul @mouseleave="toggle" class="dropdown-menu">
          <li class="dropdown-menu__item">
            <img src="/images/Account.svg" alt="Account icon" />
            <a class="dropdown-menu__link" @click="moveToProfile">
              My Account
            </a>
          </li>

          <li class="dropdown-menu__item">
            <img src="/images/Signout-small-icon.svg" alt="Sign out icon" />
            <a @click="signOut" class="dropdown-menu__link" href="#"
              >Sign Out</a
            >
          </li>
        </ul>
      </li>
      <li class="menu__item help">
        <AppNeedHelp />
      </li>
    </ul>
  </nav>
</template>

<script>
import AppNeedHelp from "@/components/layouts/AppNeedHelp";
import { useRouter } from "vue-router/dist/vue-router";
import { computed, ref } from "vue";
import { useAuthStore } from "@/store/AuthStore";

const userStore = useAuthStore();

export default {
  name: "AppHeaderNavbar",
  components: { AppNeedHelp },
  setup() {
    const router = useRouter();
    function moveToProfile() {
      router.push({
        path: `/profile`,
      });
    }
    const accountOpen = ref(false);
    function toggle() {
      this.accountOpen = !this.accountOpen;
    }

    const isLoggedIn = computed(() => {
      return userStore.authenticated;
    });

    function signOut() {
      localStorage.removeItem("authenticated");
      userStore.logout();
    }
    return {
      moveToProfile,
      toggle,
      accountOpen,
      isLoggedIn,
      signOut,
    };
  },
  mounted() {
    if (localStorage.getItem("authenticated") === null) userStore.signout();
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  display: flex;
}

.menu {
  display: flex;
  @media screen and(max-width: 425px) {
    // display: none;
  }
  &__item {
    position: relative;
    font: normal normal normal 18px/24px Roboto;
    &:first-child {
      padding-right: 8px;
      margin-right: 16px;
      @media screen and(max-width: 425px) {
        margin-right: 0;
      }
    }
    &--dropdown {
    }
  }

  &__link {
    text-decoration: none;
    display: flex;
    color: var(--base-text-color);
    align-items: center;
    letter-spacing: 0;
    @media screen and(max-width: 425px) {
      font-size: 14px;
    }
    &:hover {
      //color: $menu_link_hover_color;
    }
    &--toggle {
    }

    & > span {
      margin-right: 14px;
      @media screen and(max-width: 425px) {
        margin-right: 5px;
      }
    }
    img {
      @media screen and(max-width: 425px) {
        width: 23px;
      }
    }
  }
}

.help {
  display: flex;
}
.open .dropdown-menu {
  display: block;
}

.dropdown-menu {
  font-size: 0.9rem;
  position: absolute;
  min-width: 190px;
  padding-left: 20px;
  top: 2.2rem;
  right: 0;
  display: none;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #fff;
  @media screen and(max-width: 425px) {
    min-width: 150px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:hover {
    }
    img {
      max-width: 28px;
      @media screen and(max-width: 425px) {
        max-width: 15px;
      }
    }
  }

  &__link {
    flex: 1;
    padding: 1rem 18px;
    color: var(--base-text-color);
    background-color: #fff;
    text-decoration: none;
    cursor: pointer;
    @media screen and(max-width: 425px) {
      font-size: 14px;
      padding: 7px;
    }
  }
}

.icon {
  content: "";
  position: absolute;
  background: blue;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  top: -50%;
  margin-left: 12px;
}
</style>
