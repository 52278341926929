<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header class="modal-header" id="modalTitle">
          <h2>WE ARE HERE TO HELP!</h2>
        </header>

        <section class="modal-body" id="modalDescription">
          Any questions or changes to your account please get in touch with our
          office at <a href="tel:+18605061500">(860) 506-1500</a> or
          <a href="mailto:support@buzzcharging.com">support@buzzcharging.com</a>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AppNeedHelpModal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  opacity: 1;
  flex-direction: column;
  max-width: 710px;
  border-radius: 15px;
  @media screen and (max-width: 500px) {
    max-width: 95%;
  }
}
.modal-header {
  background: var(--buzz-charging-dark-blue);
  color: #fff;
  font: normal normal bold 40px Futura;
  text-align: center;
  padding: 32px 108px;
  @media screen and (max-width: 500px) {
    padding: 20px;
    font-size: 35px;
  }
}

.modal-body {
  padding: 66px 86px;
  min-width: 100%;
  font: 25px/40px Montserrat;
  color: var(--base-text-color);
  text-align: center;
  line-height: 34px;
  max-width: 450px;
  @media screen and (max-width: 500px) {
    padding: 16px;
    font-size: 18px;
  }
  a {
    word-break: break-word;
  }
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
a {
  color: var(--buzz-charging-dark-blue);
  text-decoration: none;
}
</style>
