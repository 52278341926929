<template>
  <footer class="footer">
    <div class="footer__address">
      <p class="footer__address-company">BUZZ CHARGING</p>
      <p class="footer__address-phone">(860) 506-1500</p>
    </div>
    <div class="footer__logo">
      <router-link to="/">
        <img class="footer__logo" src="/images/SavkatLogo.svg" alt="" />
      </router-link>
      <!-- <div class="footer__socials">
        <a href="https://www.facebook.com/savkatsolar" target="_blank">
          <img src="/images/socials/Facebook.svg" alt="" />
        </a>
        <a href="https://www.twitter.com/savkatsolar" target="_blank">
          <img src="/images/socials/Twitter.svg" alt="" />
        </a>
        <a href="https://www.linkedin.com/company/savkatinc/" target="_blank">
          <img src="/images/socials/Linkedin.svg" alt="" />
        </a>
        <a href="https://www.instagram.com/savkatsolar" target="_blank">
          <img src="/images/socials/Insta.svg" alt="" />
        </a>
      </div> -->
    </div>
    <div class="footer__help">
      <AppNeedHelp />
    </div>
    <div class="footer__copyright">
      Copyright © 2022 Buzz Charging. All Rights Reserved –
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
  </footer>
</template>

<script>
import AppNeedHelp from "@/components/layouts/AppNeedHelp";
export default {
  name: "AppFooter",
  components: { AppNeedHelp },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 50px 140px 96px 140px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  color: var(--base-text-color);

  @media screen and (max-width: 1160px) {
    padding: 44px 80px;
  }
  @media screen and(max-width: 768px) {
    padding: 32px 70px 48px 70px;
    flex-direction: column;
    align-items: center;
  }
  @media screen and(max-width: 450px) {
    padding: 16px 32px 48px 32px;
  }

  &__logo {
    width: 85%;
    height: 100%;
    max-width: 293px;
    max-height: 166px;
    @media screen and (max-width: 550px) {
      margin-bottom: 16px;
    }
  }

  &__address {
    font-family: Roboto;
    white-space: pre-line;
    max-width: 156px;
    letter-spacing: 1px;

    @media screen and(max-width: 768px) {
      text-align: center;
      margin-bottom: 16px;
      max-width: 100%;
    }

    &-company {
      font: normal normal bold 20px Montserrat;
    }
    &-main {
      font: normal normal normal 18px/24px Roboto;
      white-space: pre-line;
      line-height: 26px;
    }
    &-phone {
      color: var(--buzz-charging-dark-blue);
      line-height: 26px;
      cursor: pointer;
    }
  }
  &__socials {
    display: flex;
    margin: 20px auto 0;
    justify-content: space-between;
    max-width: 172px;
  }

  &__help {
    align-items: center;
    display: flex;
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__copyright {
    background: var(--buzz-charging-dark-blue);
    color: #fff;
    position: absolute;
    padding: 12px;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    font: normal normal medium 18px/30px Montserrat;
    a {
      color: #fff;
      transition: all 0.3s;
      &:hover {
        color: var(--buzz-charging-yellow);
      }
    }
  }
}
</style>
