import { defineStore } from "pinia";
import apiAxios from "@/api";
import router from "@/router";
import { ref } from "vue";
import { useScheduleStore } from "@/store/ScheduleStepsData";

import { useToast } from "vue-toastification";
const toast = useToast();

const stepsStore = useScheduleStore();
export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      currentUser: null,
      authenticated: ref(localStorage.getItem("authenticated") === "true"),
    };
  },
  getters: {},
  actions: {
    async login(payload) {
      try {
        await apiAxios.User.login(payload).then(() => {
          this.getUserData();
          this.authenticated = true;
          localStorage.setItem("authenticated", true);
        });
        toast.success("Logged In!");
      } catch (e) {
        if (e.response.data.message === "auth_invalid_email") {
          toast.error("There is no user with such an email");
        } else if (e.response.data.message === "auth_invalid_login_password") {
          toast.error("Invalid login or password!");
        } else {
          toast.error("Invalid login or password!");
        }
        return Promise.reject(e);
      }
    },
    async getUserData() {
      try {
        const response = await apiAxios.User.getUsersData();
        this.currentUser = response;
        return response;
      } catch (e) {
        toast.error(e.message);
        return Promise.reject(e);
      }
    },
    async registerUser(payload) {
      try {
        await apiAxios.User.register(payload).then(() => {
          this.login({
            email: payload.email,
            password: payload.password,
          });
          stepsStore.nextStep();
          toast.success("Registration completed!");
        });
      } catch (e) {
        if (
          e?.response?.data?.message ===
          "undefined:customer_type:residential, commercial"
        )
          toast.error("Invalid customer type!");
        if (e?.response?.data?.message === "email_already_exist")
          toast.error("Email already exists!");
        return Promise.reject(e);
      }
    },
    async updateUserProfile(payload) {
      try {
        await apiAxios.User.updateProfile(payload).then(() => {
          // toast.success("Profile updated!");
          // console.log("Profile updated!");
        });
      } catch (e) {
        if (e.response.status === 401) {
          this.logout();
          toast.error("Autorization error");
          return;
        }
        if (e?.response?.data?.message !== undefined) {
          toast.error(e?.response?.data?.message);
        }
        return Promise.reject(e);
      }
    },
    logout() {
      this.currentUser = {};
      this.authenticated = false;
      router.push({
        path: "/",
      });
      stepsStore.resetSteps();
      stepsStore.clearInfo();
    },
    signout() {
      this.currentUser = {};
      this.authenticated = false;
      stepsStore.clearInfo();
    },
    async forgotUserPassword(payload) {
      try {
        await apiAxios.User.forgotPassword(payload).then(() => {
          toast.success(
            "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes."
          );
        });
        return "success";
      } catch (e) {
        if (e?.response?.data?.message === "auth_invalid_email") {
          toast.error("No user exists with such email!");
        } else if (e?.response?.data?.message !== undefined) {
          toast.error(e?.response?.data?.message);
        }
        return "error";
        // return Promise.reject(e);
      }
    },
    async changeUserPassword(payload) {
      try {
        await apiAxios.User.changePassword(payload).then(() => {
          toast.success("Password successfully changed!");
        });
        router.push("/");
      } catch (e) {
        if (e?.response?.data?.message === "jwt_invalid_token") {
          // toast.error(e?.response?.data?.message);
          toast.error("This url is broken!");
        } else if (e?.response?.data?.message !== undefined) {
          toast.error(e?.response?.data?.message);
        }
        return Promise.reject(e);
      }
    },
  },
  persist: true,
});
