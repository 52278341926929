<template>
  <a class="link" href="javascript:void(0)" @click="showModal = true">
    <span>Need Help</span>
    <img src="/images/NeedHelp.svg" alt="" />
  </a>
  <AppNeedHelpModal v-if="showModal" @close="showModal = false" />
</template>

<script>
import AppNeedHelpModal from "@/components/layouts/AppNeedHelpModal";
export default {
  name: "AppNeedHelp",
  components: { AppNeedHelpModal },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
  display: flex;
  color: var(--base-text-color);
  align-items: center;
  letter-spacing: 0;
  &:hover {
    //color: $menu_link_hover_color;
    cursor: pointer;
  }
  &--toggle {
  }

  & > span {
    margin-right: 14px;
    font: normal normal normal 18px/24px Roboto;
    letter-spacing: 0px;
    @media screen and(max-width: 425px) {
      font-size: 14px;
      margin-right: 5px;
    }
  }

  img {
    @media screen and(max-width: 425px) {
      width: 25px;
    }
  }
}
</style>
