<template>
  <header class="header">
    <router-link to="/">
      <img class="header__logo" src="/images/SavkatLogo.svg" alt="" />
    </router-link>
    <div class="header__actions">
      <AppHeaderNavbar />
    </div>
  </header>
</template>

<script>
import AppHeaderNavbar from "@/components/layouts/AppHeaderNavbar";
// import { useRouter } from "vue-router";
// import { useAuthStore } from "@/store/AuthStore";

// const authStore = useAuthStore();
export default {
  name: "AppHeader",
  components: { AppHeaderNavbar },
  data() {
    return {
      isModalVisible: false,
    };
  },
  // computed: {
  //   logoLink() {
  //     return authStore.authenticated ? "/profile" : "/";
  //   },
  // },
  // setup() {
  //   const router = useRouter();

  //   // function showModal() {
  //   //   this.isModalVisible = true;
  //   // }
  //   // function closeModal() {
  //   //   this.isModalVisible = false;
  //   // }
  //   // function moveToHome() {
  //   //   if (authStore.authenticated) {
  //   //     router.push({
  //   //       path: `/profile`,
  //   //     });
  //   //   } else {
  //   //     router.push({
  //   //       path: `/`,
  //   //     });
  //   //   }
  //   // }

  //   return {
  //     moveToHome,
  //   };
  // },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 36px 140px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  box-shadow: 0 3px 6px #00000029;
  background: #fff;
  @media screen and (max-width: 1440px) {
    padding: 36px 110px;
  }
  @media screen and (max-width: 1160px) {
    padding: 36px 60px;
  }
  @media screen and (max-width: 550px) {
    padding: 24px 30px;
  }
  @media screen and (max-width: 375px) {
    padding: 10px 15px;
  }
  a {
    &:hover {
      cursor: pointer;
    }
  }

  &__logo {
    width: 100%;
    height: 100%;
    max-width: 210px;
    max-height: 60px;
    @media screen and(max-width: 425px) {
      max-width: 90px;
    }
  }
  &__actions {
    align-self: center;
  }
}
</style>
